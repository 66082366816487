import React from 'react'
import { graphql } from 'gatsby'

// Constants
import { THEMECONTENT } from '@constants'

// Elements
import { Par, List } from '@theme'
import { Layout } from '@layouts'
import { Artical } from '@sections'
import { Logo, BackgroundImage } from '@components'
import { TemplateSection, BannerLogo } from './supplier-template.styled'

const theme = 'white'

export default ({ data, pageContext: { supplier } }, props) => {
	return (
		<Layout template props={props}>
			<TemplateSection>
				<BackgroundImage name={'template-background.jpg'} bgAlpha={0}>
					<BannerLogo>
						<Logo logo={supplier.name} width="15vw" logoFill="white" />
					</BannerLogo>
				</BackgroundImage>

				<Artical titleAs="h2" titleClass="tempTitle" title={supplier.company}>
					<a className="supplierUrl" href={supplier.url} target="_blank">
						{supplier.cleanUrl}
					</a>
					<Par marginTop="0.8em" clr={THEMECONTENT[theme].par}>
						{supplier.info.map((info) => info)}
					</Par>
				</Artical>

				<Artical theme="chalk" titleClass="fileTitle" title="Product Information">
					<List
						activeGrid
						clr={THEMECONTENT.white.par}
						linkClr="coal"
						min={14}
						max={20}
					>
						{data &&
							data.allFile.nodes.map(({ name, publicURL }) => {
								return (
									<li key={`pdf-${name}`}>
										<a href={publicURL} download>
											{name}
										</a>
									</li>
								)
							})}
					</List>
				</Artical>
			</TemplateSection>
		</Layout>
	)
}

export const pageQuery = graphql`
	query FileQuery($dir: String) {
		allFile(filter: { relativeDirectory: { eq: $dir }, ext: { eq: ".pdf" } }) {
			nodes {
				name
				publicURL
			}
		}
	}
`
