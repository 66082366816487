import styled from 'styled-components'

// Theme
import { fluidFontSize } from '@theme'

export const TemplateSection = styled.section`
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	width: 100%;
	height: auto;

	.tempTitle {
		text-transform: uppercase;
		${({ min = 18, max = 40 }) => fluidFontSize(min, max)}
	}

	.supplierUrl {
		color: ${({ theme }) => theme.modify.rgba('secondary', 0.8)};

		&:hover {
			color: ${({ theme }) => theme.modify.rgba('secondary', 1)};
		}
	}

	.fileTitle {
		margin-bottom: 1em;
		${({ min = 18, max = 28 }) => fluidFontSize(min, max)}
	}
`
export const BannerLogo = styled.div`
	width: 100%;
	height: 300px;
	height: 25vw;
	display: flex;
	justify-content: center;
	align-items: center;
`
